import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
// import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { AgentBasic } from "../share/agentdetail/agent-basic";
import { AgentBalanceLog } from "../share/agentdetail/agent-balance-log";

import { AgentBalance } from "../share/agentdetail/agnet-balance";
import { AgentBenefit } from "../share/agentdetail/agent-benefit";

// import { UserCalculate } from "../share/userdetail/user-calculate";

import { AgentService } from "../service/agent.service";

let REACT_APP_API_CUSTOM = process.env.REACT_APP_API_CUSTOM;

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converAgentStatus,
  convertAgentBenefitBalanceText
} from "../utility/help";

interface Props {
  agentId: string;
}

interface State {
  agent: any;
  userTableValue: any[];
  page: number;
  pages: any;
  field: string;
  benefits: any[];
}

export class AgentDetail extends Component<Props, State> {
  agentService: AgentService;
  constructor(props: Props) {
    super(props);
    this.state = {
      agent: {},
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      benefits: []
    };

    this.agentService = new AgentService();

    // this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);

    this.agentServicehandleGetAgentBen()
  }

  componentDidMount() {

  }

  agentServicehandleGetAgentBen = async()=>{
    const data = await this.agentService.admin_get_agent_benefit(this.props.agentId).then(s => s)
    if(data.status == "success") {
      this.setState({benefits : data.benefits })
    }
  }

  handleUpdateBenefit = async(benefit_type : string) =>{

    const benefit= Object.values(this.state.benefits).find(s => s.benefit_type == benefit_type)
    if(benefit == null){
      return
    }

    const data = await this.agentService.admin_update_agent_benefit(benefit).then(s => s)
    if(data.status == "success") {
      confirmAlert({
        title: "베네핏 ",
        message: "수정에 성공하였습니다",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              }
          }

          ]})
    }
    else if(data.status === "find_agent") {
      confirmAlert({
        title: "베네핏 ",
        message: "해당 에이전트에서는 요율변경이 불가능합니다",
        buttons: [{ label: "확인", onClick: () => { }}]})
    }
    else {
      confirmAlert({
        title: "베네핏 ",
        message: "",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              }
          }

          ]})
    }

  }

  handleUserChange = (e: any) => {
    if (this.state.agent[e.target.id] != undefined) {
      this.state.agent[e.target.id] = e.target.value;

      this.setState({ agent: this.state.agent });
    }
  };

  render() {
    // let agent = this.state.agent;
    // if (agent == undefined || agent.agent == undefined) {
    //   return <div></div>;
    // }

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="card card-w-title">
                <div className="card-title">에이전트 기본정보</div>
                <div className="p-grid">
                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">아이디</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px"
                      }}
                    >
                      {this.props.agentId}
                    </p>
                  </div>

                </div>
              </div>
            </div>

            <div className="p-col-9">
             {
              REACT_APP_API_CUSTOM === 'Y' && (
                Object.values(this.state.benefits).map(s => (
                  <div className="card card-w-title">
                  <div className="p-fluid p-grid p-formgrid">
                    <div className="p-field p-col-12 p-lg-3">{convertAgentBenefitBalanceText(s.benefit_type)}</div>
                      <div className="p-field p-col-12 p-md-4">

                             <InputText
                                id="username"
                                value={s.percent}
                                 onChange={(event: any) => {
                                  var benefit = this.state.benefits.find(k => k.benefit_type === s.benefit_type)
                                  if(benefit != null){
                                    benefit.percent = event.target.value
                                    this.setState({benefits: this.state.benefits});
                                  }
                                 }}
                            />

                        </div>
                        <div className="p-field p-col-12 p-lg-3">
                            <Button
                            label="수정"
                            onClick={() => {
                                this.handleUpdateBenefit(s.benefit_type);
                            }}
                            />
                        </div>
                        </div>
                    </div>
                ))
              )
            } 

            </div>
          </div>
        </div>
      </div>
    );
  }
}
