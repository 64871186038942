import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { confirmAlert } from "react-confirm-alert"; // Import


import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertNick,

} from "../utility/help";
import { CONNREFUSED } from "dns";



interface State {
  maxCount: number;
  userTableValue: any[];
  agentTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
  level: any;
}

export class RegUserList extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      agentTableValue: [],
      
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1,
      level: 4,
      
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_and_agent_wait(page, find, search, sortType, sort, "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          agentTableValue: data.agents,
          
          maxCount: data.maxCount,
          level: data.level,
          
        });
      });
  };



  
  GetConnectUserList = () => {
    this.userService
      .get_user_health_list(0, '', '', '', 'regdate', "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          agentTableValue: data.agents,

          maxCount: data.maxCount,
        });
      });
  };



  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };


  UpdateUserBalacne = () =>{
    this.userService
    .admin_get_update_all_balance()
    .then((data) => {    

      if (data.status === "success") {
        confirmAlert({
          title: "확인.",
          message: "30초이후에 자동 새로고침됨니다.",
          buttons: [
            {
              label: "예",
              onClick: () => {
                setTimeout(function () {
                  window.location.reload()
                }, 30000);


              }
            },
          ]
        });
      }
      else {
        confirmAlert({
          title: "에러.",
          message: `해당기능은 ${data.time} 이후에 사용가능합니다.`,
          buttons: [
            {
              label: "예",
              onClick: () => {

              }
            },
          ]
        });
      }
    });

  }

  MoveSel = (page: any) => {
    this.setState({ page: page });
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                  <span className="md-inputfield">유저 가입대기</span>
            </div>

        
  

<DataTable
          value={this.state.userTableValue}
          rows={99999}
          sortOrder={-1}
          scrollable={true}
          style={{ textAlign: "center"}}

        >


          <Column
            style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"가입일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.regdate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="아이디"
            body={(rowData : any) => {
              return (
                <div >
            
                    {rowData.id}
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="닉네임"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.nick}</div>
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="이름"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.bankowner}</div>
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"보유캐시"}
            field="balance"
            body={(rowData : any) => {
              return (
                  <span>
                    {numTomoeny(0)}
                  </span>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"금고보유액"}

            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.point}</span>
                </div>
              );
            }}
          />

        

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속 IP"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.loginip}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.logindate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "left", width: "200px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "left",width: "200px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"에이전트"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(this.props.level, rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                </div>
              );
            }}
          />

          {/* {RenderReg()} */}
        </DataTable>



              <div className="card card-w-title" style={{marginTop : '20px'}}>
                  <span className="md-inputfield">에이전트 가입대기</span>
            </div>

        
  

<DataTable
          value={this.state.agentTableValue}
          rows={99999}
          sortOrder={-1}
          scrollable={true}
          style={{ textAlign: "center"}}

        >


          <Column
            style={{ textAlign: "center", width: "120px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "120px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"가입일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.regdate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="아이디"
            body={(rowData : any) => {
              return (
                <div>
                    {rowData.agent}
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="닉네임"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.nick}</div>
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header="이름"
            body={(rowData : any) => {
              return (
                <div>
                  <div id={"contextMenu"}>{rowData.bankowner}</div>
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"보유캐시"}
            field="balance"
            body={(rowData : any) => {
              return (
                  <span>
                    {numTomoeny(0)}
                  </span>
              );
            }}
          />



          <Column
            style={{ textAlign: "center", width: "100px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            bodyStyle={{ textAlign: "right"}}
            header={"금고보유액"}

            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.point}</span>
                </div>
              );
            }}
          />

        

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속 IP"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{rowData.loginip}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" , borderRight: '1px solid #d8d8d8'}}
            headerStyle={{ textAlign: "center",width: "100px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"접속일"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span>{convertDate(rowData.logindate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "left", width: "200px", borderRight: '1px solid #d8d8d8' }}
            headerStyle={{ textAlign: "left",width: "200px" ,background: '#27765c', color : '#fff', borderRight: '1px solid #d8d8d8'}}
            header={"에이전트"}
            field="balance"
            body={(rowData : any) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(this.props.level, rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                </div>
              );
            }}
          />

          {/* {RenderReg()} */}
        </DataTable>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
