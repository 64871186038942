import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  ConvertNick
} from "../../utility/help";

interface BalanceProps {
  mode: any;
  balances: any;
  level : any;
  OnEditItem: (par1: any, par2: any, par3: any) => any;
  OnSelectItem: (par: any) => any;
}

export class BalanceTable extends Component<BalanceProps> {
  constructor(props: BalanceProps) {
    super(props);
    this.state = {
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
    this.header = this.header.bind(this);
  }

  componentDidMount = () => {};

  header = (up: any, down: any) => {
    return (
      <div>
        <span>{up}</span>
        <br />
        <span>{down}</span>
        <br />
      </div>
    );
  };

  render = () => {
    const mode = this.props.mode;
    const balances = this.props.balances;
    const OnEditItem = this.props.OnEditItem;
    const OnSelectItem = this.props.OnSelectItem;
    const CheckRender = () => {
      if (mode == "deporsitAndwithdraw") {
        return <div style={{ width: "0px" }}></div>;
      }

      return (
        <Column
          style={{ textAlign: "center", width: "3px" }}
          header=""
          body={(rowData: any, column: any) => {
            return (
              <div>
                <Checkbox
                  value="n"
                  inputId={"idx" + rowData.idx}
                  onChange={() => {
                    OnSelectItem(rowData.idx);
                  }}
                  checked={rowData.sel == "y" ? true : false}
                />
                <label htmlFor="cb1" className="p-checkbox-label"></label>
              </div>
            );
          }}
        />
      );
    };

    console.log(mode)
    return (
      <DataTable
        value={balances}
        paginatorPosition="both"
        rows={999}

        sortOrder={-1}
        scrollable={true}
      >
        {CheckRender()}
   


         <Column
             style={{ textAlign: "center", width: "100px" }}
             header="종류"
             body={(rowData: any, column: any) => {
                 if (rowData.logType == "deposit") {
                     return <div style={{ color: 'red' }} >유저 충전 </div>;
                 }

                 if (rowData.logType == "agentdeposit") {
                     return <div style={{ color: 'red' }} >에이전트 충전</div>;
                 }

                 if (rowData.logType == "admindeposit") {
                     return <div style={{ color: 'red' }} >관리자 충전 </div>;
                 }

                 if (rowData.logType == "withdraw") {
                     return <div style={{ color: 'blue' }} >유저 환전</div>;
                 }

                 if (rowData.logType == "agentwithdraw") {
                     return <div style={{ color: 'blue' }} >에이전트 환전</div>;
                 }

                 if (rowData.logType == "adminwithdraw") {
                     return <div style={{ color: 'blue' }} >관리자 환전</div>;
                 }

                 if (rowData.logType == "agent_deposit_agent") {
                  return <div style={{ color: 'red' }} >{`에이저트 => 에이전트 충전`}</div>;
                }   
                
                if (rowData.logType == "agent_withdraw_agent") {
                  return <div style={{ color: 'blue' }} >{`에이저트 => 에이전트 환전`}</div>;
                 }

                 return "미정의";
             }}
         />

         {
           mode === 'deporsitAndwithdraw' ?(
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="아이디"
              field="id"
              body={(rowData: any, column: any) => {


                  return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.id}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  );
              }}
          />
           ) : (
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="에이전트 =>"
              field="id"
              body={(rowData: any, column: any) => {
                  if(rowData.logType == "agent_withdraw_agent"){
                    return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.agent}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  );
                  }

                  return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.giveId}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  );
              }}
          />

           )
         }


          {
           mode === 'deporsitAndwithdraw' ?(
  
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="에이전트"
              field="id"
              body={(rowData: any, column: any) => {
            
                  return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.agent}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  );
              }}
          />
           ) : (
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="에이전트"
              field="id"
              body={(rowData: any, column: any) => {
                  if(rowData.logType == "agent_withdraw_agent"){
                    return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.giveId}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  )}

                  return (
                      <div
                          style={{ color: rowData.color }}
                          onClick={() => {
                          }}
                      >
                          {rowData.targetId}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                      </div>
                  );
              }}
          />

           )
         }


         <Column
             style={{ textAlign: "center", width: "100px" }}
             header="입금/출금"
             body={(rowData: any, column: any) => {
                 return (
                     <div style={{ color: rowData.balance > 0 ? getDepositColor() : getWithdrawColor() }}>
                         {numTomoeny(rowData.balance)}
                     </div>
                 );
             }}
         />

         <Column
             style={{ textAlign: "center", width: "140px" }}
             header={"완료일"}
             body={(rowData: any, column: any) => {
                 return (
                     <div>
                         <span>{convertDate(rowData.regdate)}</span>
                     </div>
                 );
             }}
         />
         <Column
             style={{ textAlign: "center", width: "300px" }}
             header={"소속"}
             bodyStyle={{ textAlign: "left", width: "300px" }}
             body={(rowData: any, column: any) => {
                 return (
                     <div>{rowData.agent}</div>
                 );
             }}
         />

      </DataTable>
    );
  };
}
